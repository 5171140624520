import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import ColorSchemeToggle from '../components/ColorSchemeToggle';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Button from '@mui/joy/Button';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import * as Icon from 'react-feather';
import axios from 'axios';
import '../../node_modules/react-vis/dist/style.css';
import {XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineMarkSeries} from 'react-vis';

function FM_NHS() {
  const [sentiment, setIndex] = useState('');

  useEffect(() => {
    getNHSHPSI();
 }, []);

  const getNHSHPSI = () => {
    const url = `${process.env.REACT_APP_API_URL}/fm/nhs/hpsi`;
    axios.get(`${url}`)
    .then((response) => {
      const data = response.data;
      //console.log(data);
      const chartData = data.slice(0, 13).map(s => ({
        x: s.date,
        y: s.hpsiValue,
      }));
      console.log(chartData);
      setIndex(chartData.reverse());
    })
    .catch(error => console.error(`Error: $(error)`));
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<Icon.ChevronRight />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          {/* <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            href="/"
            aria-label="Home"
            disabled
          >
            <Icon.Home />
          </Link> */}
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            href="/fm"
          >
              Financial Tools
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            National Housing Survey
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          National Housing Survey
        </Typography>
        
      </Box>

      <Box className="SearchAndFilters-tabletUp">
        <FormControl size="lg">
          <FormLabel>The Fannie Mae Home Purchase Sentiment Index™ (HPSI), distills results from its consumer-focused National Housing Survey™ (NHS) into a single, monthly, predictive indicator. Reflecting more than four years of data, the HPSI is designed to provide distinct signals about the direction of the housing market, helping industry participants to make better informed business decisions. </FormLabel>
        </FormControl>
      </Box>
      {/* <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: {
            xs: 'flex',
            sm: 'flex',
          },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '100%',
              md: '200px',
            },
          },
        }}
      >
        <Button 
          variant="outlined"
          onClick={() => {
            getNHSHPSI();
          }}
        >Get Survey Results</Button>
      </Box> */}
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '810px',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
          marginTop: '20px',
        }}
      >
        {sentiment ?
         (
          <div>
            <XYPlot xType="ordinal" height={500} width={800}>
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis
                style={{
                  line: {stroke: '#ADDDE1'},
                  ticks: {stroke: '#ADDDE1'},
                  text: {stroke: 'none', fill: '#6b6b76', fontWeight: 600}
                }}
              />
              <YAxis />
              <LineMarkSeries 
                curve={'curveMonotoneX'}
                lineStyle={{stroke: '#1fa2ee'}}
                fill='#288164'
                data={sentiment} />
            </XYPlot>
          </div>
         ) : (
          <Box
          sx={{
            p: 2,
          }}>
             <Typography fontWeight="md">Loading...</Typography>
          </Box>
         )}
      </Sheet>
      
    </React.Fragment>
    );
  }

export default FM_NHS;