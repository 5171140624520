import * as React from 'react';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import * as Icon from 'react-feather';
import { closeSidebar } from '../utils';
import { Link, useLocation } from 'react-router-dom';

export default function SecondSidebar() {
  const location = useLocation();
  //console.log(location);

  return (
    <React.Fragment>
      <Box
        className="SecondSidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.body',
          opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className="SecondSidebar"
        sx={{
          position: {
            xs: 'fixed',
            lg: 'sticky',
          },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'none',
          },
          borderRight: '1px solid',
          borderColor: 'divider',
          transition: 'transform 0.4s',
          zIndex: 9999,
          height: '100dvh',
          top: 0,
          p: 2,
          py: 3,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <List
          sx={{
            '--ListItem-radius': '8px',
            '--ListItem-minHeight': '32px',
            '--List-gap': '4px',
          }}
        >
          <ListSubheader role="presentation" sx={{ color: 'text.primary' }}>
            Financial Tools
          </ListSubheader>
          <ListItem component={Link} to="/fm/incomelimits" sx={{textDecoration:"none"}}>
            <ListItemButton selected={location.pathname === "/fm/incomelimits"} variant={location.pathname === "/fm/incomelimits" ? "soft" : "plain"}>
              <ListItemDecorator>
                <Icon.DollarSign/>
              </ListItemDecorator>
              <ListItemContent>Income Loan Limits</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem component={Link} to="/fm/loanlimits" sx={{textDecoration:"none"}}>
            <ListItemButton selected={location.pathname === "/fm/loanlimits"} variant={location.pathname === "/fm/loanlimits" ? "soft" : "plain"}>
              <ListItemDecorator>
                <Icon.DollarSign/>
              </ListItemDecorator>
              <ListItemContent>Loan Limits</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem component={Link} to="/fm/economic-forecasts" sx={{textDecoration:"none"}}>
            <ListItemButton selected={location.pathname === "/fm/economic-forecasts"} variant={location.pathname === "/fm/economic-forecasts" ? "soft" : "plain"}>
              <ListItemDecorator>
                <Icon.BarChart2/>
              </ListItemDecorator>
              <ListItemContent>Economic Indicators</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem component={Link} to="/fm/housing-indicators" sx={{textDecoration:"none"}}>
            <ListItemButton selected={location.pathname === "/fm/housing-indicators"} variant={location.pathname === "/fm/housing-indicators" ? "soft" : "plain"}>
              <ListItemDecorator>
                <Icon.BarChart2/>
              </ListItemDecorator>
              <ListItemContent>Housing Indicators</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem component={Link} to="/fm/nhs" sx={{textDecoration:"none"}}>
            <ListItemButton selected={location.pathname === "/fm/nhs"} variant={location.pathname === "/fm/nhs" ? "soft" : "plain"}>
              <ListItemDecorator>
                <Icon.BarChart2 />
              </ListItemDecorator>
              <ListItemContent>National Housing Survey</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </Sheet>
    </React.Fragment>
  );
}
