import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import ColorSchemeToggle from '../components/ColorSchemeToggle';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import * as Icon from 'react-feather';
import {get} from 'lodash';
import axios from 'axios';

const states = {
  GA : ["Appling","Atkinson","Bacon","Baker","Baldwin","Banks","Barrow","Bartow","Ben Hill","Berrien","Bibb","Bleckley","Brantley","Brooks","Bryan","Bulloch","Burke","Butts","Calhoun","Camden","Candler","Carroll","Catoosa","Charlton","Chatham","Chattahoochee","Chattooga","Cherokee","Clarke","Clay","Clayton","Clinch","Cobb","Coffee","Colquitt","Columbia","Cook","Coweta","Crawford","Crisp","Dade","Dawson","Decatur","DeKalb","Dodge","Dooly","Dougherty","Douglas","Early","Echols","Effingham","Elbert","Emanuel","Evans","Fannin","Fayette","Floyd","Forsyth","Franklin","Fulton","Gilmer","Glascock","Glynn","Gordon","Grady","Greene","Gwinnett","Habersham","Hall","Hancock","Haralson","Harris","Hart","Heard","Henry","Houston","Irwin","Jackson","Jasper","Jeff Davis","Jefferson","Jenkins","Johnson","Jones","Lamar","Lanier","Laurens","Lee","Liberty","Lincoln","Long","Lowndes","Lumpkin","Macon","Madison","Marion","McDuffie","McIntosh","Meriwether","Miller","Mitchell","Monroe","Montgomery","Morgan","Murray","Muscogee","Newton","Oconee","Oglethorpe","Paulding","Peach","Pickens","Pierce","Pike","Polk","Pulaski","Putnam","Quitman","Rabun","Randolph","Richmond","Rockdale","Schley","Screven","Seminole","Spalding","Stephens","Stewart","Sumter","Talbot","Taliaferro","Tattnall","Taylor","Telfair","Terrell","Thomas","Tift","Toombs","Towns","Treutlen","Troup","Turner","Twiggs","Union","Upson","Walker","Walton","Ware","Warren","Washington","Wayne","Webster","Wheeler","White","Whitfield","Wilcox","Wilkes","Wilkinson","Worth"],
  FL : ["Alachua","Baker","Bay","Bradford","Brevard","Broward","Calhoun","Charlotte","Citrus","Clay","Collier","Columbia","DeSoto","Dixie","Duval","Escambia","Flagler","Franklin","Gadsden","Gilchrist","Glades","Gulf","Hamilton","Hardee","Hendry","Hernando","Highlands","Hillsborough","Holmes","Indian River","Jackson","Jefferson","Lafayette","Lake","Lee","Leon","Levy","Liberty","Madison","Manatee","Marion","Martin","Miami-Dade","Monroe","Nassau","Okaloosa","Okeechobee","Orange","Osceola","Palm Beach","Pasco","Pinellas","Polk","Putnam","Santa Rosa","Sarasota","Seminole","St. Johns","St. Lucie","Sumter","Suwannee","Taylor","Union","Volusia","Wakulla","Walton","Washington"],
  SC : ["Abbeville","Aiken","Allendale","Anderson","Bamberg","Barnwell","Beaufort","Berkeley","Calhoun","Charleston","Cherokee","Chester","Chesterfield","Clarendon","Colleton","Darlington","Dillon","Dorchester","Edgefield","Fairfield","Florence","Georgetown","Greenville","Greenwood","Hampton","Horry","Jasper","Kershaw","Lancaster","Laurens","Lee","Lexington","Marion","Marlboro","McCormick","Newberry","Oconee","Orangeburg","Pickens","Richland","Saluda","Spartanburg","Sumter","Union","Williamsburg","York"],
  TN : ["Anderson","Bedford","Benton","Bledsoe","Blount","Bradley","Campbell","Cannon","Carroll","Carter","Cheatham","Chester","Claiborne","Clay","Cocke","Coffee","Crockett","Cumberland","Davidson","Decatur","DeKalb","Dickson","Dyer","Fayette","Fentress","Franklin","Gibson","Giles","Grainger","Greene","Grundy","Hamblen","Hamilton","Hancock","Hardeman","Hardin","Hawkins","Haywood","Henderson","Henry","Hickman","Houston","Humphreys","Jackson","Jefferson","Johnson","Knox","Lake","Lauderdale","Lawrence","Lewis","Lincoln","Loudon","McMinn","McNairy","Macon","Madison","Marion","Marshall","Maury","Meigs","Monroe","Montgomery","Moore","Morgan","Obion","Overton","Perry","Pickett","Polk","Putnam","Rhea","Roane","Robertson","Rutherford","Scott","Sequatchie","Sevier","Shelby","Smith","Stewart","Sullivan","Sumner","Tipton","Trousdale","Unicoi","Union","Van Buren","Warren","Washington","Wayne","Weakley","White","Williamson","Wilson"]
};

function formatDate(date) {
  const text = date.toString();
  const year = text.slice(0, 4);
  return year;
}

function formatCurrency(price) {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 9,
  });
  return USDollar.format(price);
}

function FM_LoanLimits() {
  const [selectedState, setSelectedState] = React.useState('');
  const [selectedCounty, setSelectedCounty] = useState();
  const [limits, setLimits] = useState('');

  const getLimits = () => {
    if (selectedCounty != null) {
      const state = selectedState;
      const county = selectedCounty;
      const url = `${process.env.REACT_APP_API_URL}/fm/loan-limits/state/${state}/county/${county}`;
      axios.get(`${url}`)
      .then((response) => {
        const data = response.data;
        console.log(data);
        setLimits(data);
      })
      .catch(error => console.error(`Error: $(error)`));
    }
  };

  const selectStateChange = (e, newValue) => {
    console.log(newValue);
    setSelectedState(newValue);
    setSelectedCounty();
  };

  const selectCountyChange = (e, newValue) => {
    console.log(newValue);
    setSelectedCounty(newValue);
  };

  useEffect(() => {
    // run something every time name changes
    console.log('State: ' + selectedState + ' County: ' +  selectedCounty);
    getLimits();
  }, [selectedCounty])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<Icon.ChevronRight />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          {/* <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            href="/"
            aria-label="Home"
            disabled
          >
            <Icon.Home />
          </Link> */}
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            href="/fm"
          >
              Financial Tools
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            Loan Limits
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          Loan Limits
        </Typography>
        
      </Box>
      <Box className="SearchAndFilters-tabletUp">
        <FormControl size="lg">
          <FormLabel>Search for state & county loan limits</FormLabel>
        </FormControl>
      </Box>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: {
            xs: 'flex',
            sm: 'flex',
          },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '100%',
              md: '200px',
            },
          },
        }}
      >
        <FormControl size="md">
          <FormLabel>State</FormLabel>
          <Select
            placeholder="Choose state"
            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
            onChange={selectStateChange}
          >
            {Object.keys(states).map((value) => (
                <Option value={value} key={value}>{value}</Option>
            ))}
          </Select>
        </FormControl>

        <FormControl size="md">
          <FormLabel>Select County</FormLabel>
          <Select disabled={(selectedState) ? false : true}
            placeholder={(selectedState) ? "Choose county" : "Choose a state first"}
            onChange={selectCountyChange}
          >
            {get(states,selectedState, []).map((value) => (
                  <Option value={value} key={value}>{value}</Option>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {selectedCounty != null && limits ?
         (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': (theme) =>
                theme.vars.palette.background.level1,
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': (theme) =>
                theme.vars.palette.background.level1,
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: 140, padding: 12 }}>
                    <Typography fontWeight="md">State</Typography>
                </td>
                <td>{limits.loanLimit[0].stateCode}</td>
              </tr>
              <tr>
                <td style={{ width: 140, padding: 12 }}>
                    <Typography fontWeight="md">County</Typography>
                </td>
                <td>{limits.loanLimit[0].countyName}</td>
              </tr>
              <tr>
                <td style={{ width: 140, padding: 12 }}>
                    <Typography fontWeight="md">Reporting Year</Typography>
                </td>
                <td>{formatDate(limits.loanLimit[0].reportingYear)}</td>
              </tr>
              <tr>
                <td style={{ width: 140, padding: 12 }}>
                    <Typography fontWeight="md">Issuer Type</Typography>
                </td>
                <td>{limits.loanLimit[0].issuers[0].issuerType}</td>
              </tr>
              <tr>
                <td style={{ width: 140, padding: 12 }}>
                    <Typography fontWeight="md">One Unit Limit</Typography>
                </td>
                <td>{formatCurrency(limits.loanLimit[0].issuers[0].oneUnitLimit)}</td>
              </tr>
              <tr>
                <td style={{ width: 140, padding: 12 }}>
                    <Typography fontWeight="md">Two Unit Limit</Typography>
                </td>
                <td>{formatCurrency(limits.loanLimit[0].issuers[0].twoUnitLimit)}</td>
              </tr>
              <tr>
                <td style={{ width: 140, padding: 12 }}>
                    <Typography fontWeight="md">Three Unit Limit</Typography>
                </td>
                <td>{formatCurrency(limits.loanLimit[0].issuers[0].threeUnitLimit)}</td>
              </tr>
              <tr>
                <td style={{ width: 140, padding: 12 }}>
                    <Typography fontWeight="md">Four Unit Limit</Typography>
                </td>
                <td>{formatCurrency(limits.loanLimit[0].issuers[0].fourUnitLimit)}</td>
              </tr>
            </tbody>
          </Table>
         ) : (
          <Box
          sx={{
            p: 2,
          }}>
             <Typography fontWeight="md">Choose a state and county</Typography>
          </Box>
         )}
      </Sheet>
      
    </React.Fragment>
    );
  }

export default FM_LoanLimits;