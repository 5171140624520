import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import ColorSchemeToggle from '../components/ColorSchemeToggle';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import * as Icon from 'react-feather';
import {get} from 'lodash';
import axios from 'axios';

const states = [
  {value: 'GA', label: 'GA'},
  {value: 'FL', label: 'FL'},
  {value: 'SC', label: 'SC'},
  {value: 'TN', label: 'TN'}
];

function formatDate(date) {
  const text = date.toString();
  const year = text.slice(0, 4);
  return year;
}

function formatCurrency(price) {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 9,
  });
  return USDollar.format(price);
}

function formatIndicator(indicator) {
  if (indicator) {
    return 'Yes'
  } else {
    return 'No';
  }
}

function FM_IncomeLimits() {
  const [stateError, setStateError] = useState(false);
  const [fipsError, setFipsError] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [limits, setLimits] = useState('');
  const [tableHeader, setHeader] = useState('');

  const getAddressLimits = (e) => {
    e.preventDefault();
    document.getElementById("trackLimits").reset();

    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    //console.log(formJson);
    if (formJson.state == '') {
      setStateError(true);
    } else {
      setStateError(false);
      const label = `Address: ${formJson.bldNumber} ${formJson.street} ${formJson.city}, ${formJson.state} ${formJson.zipCode}`
      setHeader(label);
    }

    if (stateError !== true) {
      const url = `${process.env.REACT_APP_API_URL}/fm/income-limits/addresscheck?number=${formJson.bldNumber}&street=${formJson.street}&city=${formJson.city}&state=${formJson.state}&zip=${formJson.zipCode}`;
      console.log(url);
      axios.get(`${url}`)
      .then((response) => {
        const data = response.data;
        console.log(data);
        setLimits(data);
      })
      .catch(error => console.error(`Error: $(error)`));
    }
  };

  const getTractLimits = (e) => {
    e.preventDefault();
    document.getElementById("addressLimits").reset();
    setSelectedState('');
    
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    //console.log(formJson);

    if (formJson.fipsCode.length !== 11) {
      setFipsError(true);
      return;
    } else {
      setFipsError(false);
      const label = `FIPS Code: ${formJson.fipsCode}`
      setHeader(label);
    }

    if (stateError !== true) {
      const url = `${process.env.REACT_APP_API_URL}/fm/income-limits/censustracts?fips_code=${formJson.fipsCode}`;
      console.log(url);
      axios.get(`${url}`)
      .then((response) => {
        const data = response.data;
        console.log(data);
        setLimits(data);
      })
      .catch(error => console.error(`Error: $(error)`));
    }
  }

  const selectStateChange = (e, newValue) => {
    setSelectedState(newValue);
    setStateError(false);
  };

  const resetState = () => {
    setSelectedState('');
    setLimits('');
    setHeader('');
  }
  
  useEffect(() => {
    // run something every time form is submitted changes
  }, [stateError, fipsError])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<Icon.ChevronRight />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          {/* <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            href="/"
            aria-label="Home"
            disabled
          >
            <Icon.Home />
          </Link> */}
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            href="/fm"
          >
              Financial Tools
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            Income Loan Limits
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          Income Loan Limits
        </Typography>
        
      </Box>
      <Box className="SearchAndFilters-tabletUp">
        <FormControl size="lg">
          <FormLabel>Income Limits for HomeReady(R) and other AMI-based loan products</FormLabel>
        </FormControl>
      </Box>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: {
            xs: 'flex',
            sm: 'flex',
          },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '100%',
              md: '300px',
            },
          },
        }}
      >
        <Box
          sx={{
            borderRadius: 'sm',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 300,
            py: 2,
            maxWidth: '100%',
            '& form': {
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            },
          }}
        >
          <Typography level="h4">
            Address Check
          </Typography>

          <form id='addressLimits' onSubmit={getAddressLimits}>
              <FormControl required>
                <FormLabel>Building Number</FormLabel>
                <Input name="bldNumber" defaultValue="" placeholder="321" type="number" variant="outlined" color="primary" />
              </FormControl>

              <FormControl required>
                <FormLabel>Street</FormLabel>
                <Input name="street" placeholder="Anywhere St" variant="outlined" color="primary" />
              </FormControl>

              <FormControl required>
                <FormLabel>City</FormLabel>
                <Input name="city" placeholder="Atlanta" variant="outlined" color="primary" />
              </FormControl>

              <FormControl size="md" required error={stateError}>
                <FormLabel>State</FormLabel>
                <Select
                  name="state"
                  value={selectedState}
                  placeholder="Choose state"
                  variant="outlined"
                  indicator={null}
                  color={stateError === true ? "danger" : "primary"}
                  slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                  onChange={selectStateChange}
                >
                  {states.map(option => (
                      <Option value={option.value} key={option.value}>{option.label}</Option>
                  ))}
                </Select>
                {stateError && <FormHelperText>Select a state.</FormHelperText>}
              </FormControl>

              <FormControl required>
                <FormLabel>Zip Code</FormLabel>
                <Input name="zipCode" placeholder="30309" type="number" variant="outlined" color="primary" />
              </FormControl>

              <Button 
                variant="outlined"
                type="submit"
              >Get Income Limits</Button>

              <Button 
                variant="outlined"
                type="reset"
                onClick={resetState}
              >Reset</Button>

          </form>
        </Box>

        <Box
          sx={{
            borderRadius: 'sm',
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            width: 100,
            py: 2,
            maxWidth: '100%',
          }}
        >
          <Typography level="h4">
            - OR -
          </Typography>
        </Box>

        <Box
          sx={{
            borderRadius: 'sm',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 300,
            py: 2,
            maxWidth: '100%',
            '& form': {
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            },
          }}
        >
          <Typography level="h4">
            Census Tract
          </Typography>

          <form id='trackLimits' onSubmit={getTractLimits}>
              <FormControl required error={fipsError}>
                <FormLabel>FIPS Code</FormLabel>
                <Input 
                  name="fipsCode" 
                  placeholder="A single 11 digit FIPS code" 
                  type="number" 
                  variant="outlined" 
                  color={fipsError === true ? "danger" : "primary"} />
                {fipsError && <FormHelperText>FIPS code must be 11 digits.</FormHelperText>}
              </FormControl>

              <Button 
                variant="outlined"
                type="submit"
              >Get Income Limits</Button>

              <Button 
                variant="outlined"
                type="reset"
                onClick={resetState}
              >Reset</Button>

          </form>
        </Box>

    </Box>
        
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {limits !== '' ?
         (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': (theme) =>
                theme.vars.palette.background.level1,
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': (theme) =>
                theme.vars.palette.background.level1,
            }}
          >
            <thead>
              <tr>
                <th colSpan={2}>
                  <Typography fontWeight="lg">{tableHeader}</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                    <Typography fontWeight="md">Duty to Serve (DTS) Income Limit</Typography>
                </td>
                <td>
                  <Typography fontWeight="lg">{formatCurrency(limits.incomeLimitsList[0].dts_income_limit)}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                    <Typography fontWeight="md">Low Income (LI) / HomeReady Income Limits</Typography>
                </td>
                <td>
                  <Typography fontWeight="lg">{formatCurrency(limits.incomeLimitsList[0].hr_income_limit)}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                    <Typography fontWeight="md">Very Low Income (VLI) Income Limits</Typography>
                </td>
                <td>
                  <Typography fontWeight="lg">{formatCurrency(limits.incomeLimitsList[0].vli_income_limit)}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                    <Typography fontWeight="md">High Needs Rural Indicator</Typography>
                </td>
                <td>
                  <Typography fontWeight="lg">{formatIndicator(limits.incomeLimitsList[0].high_needs_rural_indicator)}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                    <Typography fontWeight="md">Rural Indicator</Typography>
                </td>
                <td>
                  <Typography fontWeight="lg">{formatIndicator(limits.incomeLimitsList[0].rural_indicator)}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                    <Typography fontWeight="md">FIPS Code</Typography>
                </td>
                <td>
                  <Typography fontWeight="lg">{limits.incomeLimitsList[0].fips_code}</Typography>
                </td>
              </tr>
            </tbody>
          </Table>
         ) : (
          <Box
          sx={{
            p: 2,
          }}>
             <Typography fontWeight="md">Complete either the address check or census tract form</Typography>
          </Box>
         )}
      </Sheet>
      
    </React.Fragment>
    );
  }

export default FM_IncomeLimits;