import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import SFALogo from './SFALogo';
import { openSidebar } from '../utils';
import * as Icon from 'react-feather';
import { Link, useLocation } from 'react-router-dom';

export default function FirstSidebar() {
  const location = useLocation();

  return (
    <Sheet
      className="FirstSidebar"
      variant="soft"
      color="primary"
      invertedColors
      sx={{
        position: {
          xs: 'fixed',
          md: 'sticky',
        },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--FirstSidebar-width)',
        top: 0,
        p: 1.5,
        py: 3,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={{
          ':root': {
            '--FirstSidebar-width': '68px',
          },
        }}
      />
      <SFALogo />
      <List sx={{ '--ListItem-radius': '8px', '--List-gap': '12px' }}>
        {/* <ListItem>
          <ListItemButton selected={location.pathname === "/"} variant="plain" disabled>
            <Icon.Home/>
          </ListItemButton>
        </ListItem> */}
        <ListItem>
          <ListItemButton
            selected={location.pathname === "/fm"}
            variant="solid"
            color="primary"
            onClick={() => openSidebar()}
            aria-label="financial tools"
          >
            <Icon.Trello/>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      
    </Sheet>
  );
}
