import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/Home';
import FannieMae from '../pages/FannieMaeHome';
import FMLoanLimits from '../pages/FM_LoanLimits';
import FMNHS from '../pages/FM_NHS';
import FMEconomicForecasts from '../pages/FM_EconomicForecasts';
import FMHousingIndicators from '../pages/FM_HousingIndicators';
import FMIncomeLimits from '../pages/FM_IncomeLimits';

function Page() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<FannieMae />} />
        <Route path="/fm" element={<FannieMae />} />
        <Route path="/fm/incomelimits" element={<FMIncomeLimits />} />
        <Route path="/fm/loanlimits" element={<FMLoanLimits />} />
        <Route path="/fm/nhs" element={<FMNHS />} />
        <Route path="/fm/economic-forecasts" element={<FMEconomicForecasts />} />
        <Route path="/fm/housing-indicators" element={<FMHousingIndicators />} />
      </Routes>
    </div>
  );
}

export default Page;