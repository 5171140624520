import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Typography from '@mui/joy/Typography';
import Link from '@mui/joy/Link';
import ColorSchemeToggle from '../components/ColorSchemeToggle';
import * as Icon from 'react-feather';

function FannieMaeHome() {

  return (
    <React.Fragment>
       <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<Icon.ChevronRight />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          {/* <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            href="/"
            aria-label="Home"
            disabled
          >
            <Icon.Home />
          </Link> */}
          <Typography fontSize="inherit" variant="soft" color="primary">
            Financial Tools
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          Financial Tools
        </Typography>
      </Box>

      <Box className="SearchAndFilters-tabletUp">
        <Typography level="body1">
          Select a tool option from the menu.
        </Typography>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '50%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
          backgroundColor: '#fff',
          marginTop: '20px',
        }}
      >
        <Box
        variant="outlined"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          minHeight: '300px',
          px: 2,
          py: 2,
        }}
        >
          <svg id="Layer_3" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 155.57 100.44" fill="none" style={{backgroundColor: '#fff'}}>

              <path d="m9.71,71.3c-1.81,0-3.43-.21-4.84-.63-1.41-.42-2.76-1.11-4.04-2.08-.28-.17-.49-.37-.62-.61-.14-.24-.21-.49-.21-.74,0-.4.18-.76.53-1.07.35-.32.79-.47,1.32-.47.4,0,.78.12,1.13.35.96.74,1.96,1.29,3,1.67,1.04.38,2.27.57,3.68.57,1.01,0,1.93-.14,2.78-.41.84-.27,1.52-.64,2.02-1.1.5-.46.76-1,.76-1.61,0-.76-.26-1.38-.77-1.87-.52-.49-1.26-.9-2.23-1.23-.97-.33-2.13-.6-3.5-.83-1.26-.19-2.39-.45-3.38-.79-1-.34-1.84-.76-2.55-1.27-.71-.51-1.25-1.13-1.62-1.84-.38-.71-.57-1.54-.57-2.49,0-1.28.4-2.38,1.21-3.3.81-.92,1.9-1.64,3.29-2.14,1.38-.5,2.95-.76,4.68-.76,1.59,0,3.07.2,4.46.62,1.38.41,2.51.96,3.36,1.65.6.44.91.91.91,1.42,0,.38-.18.73-.55,1.05-.37.32-.79.49-1.27.49-.35,0-.66-.08-.91-.25-.4-.36-.94-.68-1.62-.96-.68-.28-1.4-.52-2.17-.71-.77-.19-1.51-.28-2.21-.28-1.13,0-2.1.13-2.91.39-.81.26-1.42.62-1.85,1.07-.43.45-.64.98-.64,1.59,0,.71.25,1.3.74,1.76.49.46,1.18.83,2.06,1.12.88.28,1.89.54,3.02.77,1.38.25,2.63.54,3.72.85,1.1.31,2.02.72,2.78,1.21.75.49,1.33,1.11,1.74,1.84.4.73.6,1.65.6,2.74,0,1.26-.43,2.36-1.28,3.31-.86.94-1.98,1.68-3.38,2.2-1.4.53-2.95.79-4.67.79Z" fill="#030e4f"/>
              <path d="m42.78,48.91c.55,0,.99.15,1.32.46.33.3.49.69.49,1.15v11.93c0,1.68-.44,3.18-1.32,4.5-.88,1.32-2.09,2.36-3.63,3.1-1.54.74-3.31,1.12-5.33,1.12s-3.76-.37-5.31-1.12c-1.55-.75-2.77-1.78-3.66-3.1-.89-1.32-1.34-2.82-1.34-4.5v-11.93c0-.46.19-.85.57-1.15.38-.3.87-.46,1.47-.46.48,0,.91.15,1.3.46.39.3.59.69.59,1.15v11.93c0,1.11.29,2.08.89,2.91.59.83,1.38,1.48,2.36,1.95.98.47,2.03.71,3.14.71,1.21,0,2.32-.24,3.33-.71,1.01-.47,1.82-1.12,2.44-1.95.62-.83.93-1.8.93-2.91v-11.93c0-.46.16-.85.47-1.15.31-.3.75-.46,1.3-.46Z" fill="#030e4f"/>
              <path d="m131.1,69.38c-.03.46-.23.85-.62,1.15-.39.3-.85.46-1.38.46-.58,0-1.05-.15-1.42-.46-.37-.3-.55-.69-.55-1.15v-18.83c0-.46.19-.85.57-1.15.38-.3.87-.45,1.47-.45.5,0,.95.15,1.34.45.39.3.58.69.58,1.15v18.83Z" fill="#030e4f"/>
              <path d="m136.49,51.96c-.53,0-.98-.14-1.36-.42s-.57-.66-.57-1.12.19-.8.57-1.07c.38-.27.83-.41,1.36-.41h17.19c.53,0,.98.14,1.34.42.36.28.55.65.55,1.09,0,.46-.18.83-.55,1.1-.37.27-.81.41-1.34.41h-17.19Zm8.58,19.02c-.6,0-1.09-.15-1.45-.46-.37-.3-.55-.69-.55-1.15v-18.86h4.04v18.86c0,.46-.2.85-.58,1.15-.39.3-.88.46-1.46.46Z" fill="#030e4f"/>

                <polygon points="70.66 52.55 63.64 44.92 42.91 70.84 47.3 70.84 63.81 50.59 68.01 55.44 70.66 52.55" fill="#030e4f"/>
                <polygon points="83.53 54.17 74.89 44.92 62.49 60.61 66.88 60.61 75.07 50.59 81.07 57.2 83.53 54.17" fill="#030e4f"/>
                <polygon points="100.71 56.46 105.82 50.59 122.33 70.84 126.72 70.84 105.99 44.92 98.26 53.45 100.71 56.46" fill="#030e4f"/>
                <polyline points="94.55 50.59 102.75 60.61 107.14 60.61 94.29 44.92 73.56 70.84 77.95 70.84 94.47 50.59" fill="#030e4f"/>

              <path d="m43.52,76.51c.25.23.37.54.37.92s-.12.69-.37.91-.57.34-.97.34h-3.45v11.53c0,.38-.14.7-.42.96-.28.26-.62.38-1.03.38s-.75-.13-1.01-.38-.4-.58-.4-.96v-11.53h-3.45c-.4,0-.72-.12-.97-.35-.25-.23-.37-.54-.37-.92s.13-.67.38-.9c.26-.23.58-.35.96-.35h9.77c.4,0,.72.12.97.35Z" fill="#030e4f"/>
              <path d="m63.36,87.85c-.65,1.21-1.54,2.17-2.67,2.87s-2.42,1.06-3.84,1.06-2.7-.35-3.84-1.06c-1.14-.7-2.02-1.66-2.66-2.87-.64-1.21-.96-2.54-.96-3.99s.32-2.78.96-3.99c.64-1.21,1.53-2.17,2.66-2.87,1.14-.7,2.42-1.06,3.84-1.06s2.7.35,3.84,1.06c1.14.7,2.03,1.66,2.67,2.87.65,1.21.97,2.54.97,3.99s-.32,2.78-.97,3.99Zm-2.49-6.67c-.4-.8-.95-1.43-1.65-1.9-.7-.47-1.5-.7-2.38-.7s-1.69.23-2.39.69c-.7.46-1.24,1.1-1.63,1.9-.39.81-.58,1.7-.58,2.68s.19,1.88.58,2.68c.39.81.93,1.44,1.63,1.9.7.46,1.49.69,2.39.69s1.67-.23,2.38-.7c.7-.47,1.25-1.1,1.65-1.9.4-.8.59-1.69.59-2.67s-.2-1.87-.59-2.67Z" fill="#030e4f"/>
              <path d="m84.46,87.85c-.65,1.21-1.54,2.17-2.67,2.87s-2.42,1.06-3.84,1.06-2.7-.35-3.84-1.06c-1.14-.7-2.02-1.66-2.66-2.87-.64-1.21-.96-2.54-.96-3.99s.32-2.78.96-3.99c.64-1.21,1.53-2.17,2.66-2.87,1.14-.7,2.42-1.06,3.84-1.06s2.7.35,3.84,1.06c1.14.7,2.03,1.66,2.67,2.87.64,1.21.97,2.54.97,3.99s-.32,2.78-.97,3.99Zm-2.49-6.67c-.4-.8-.95-1.43-1.65-1.9-.7-.47-1.5-.7-2.38-.7s-1.69.23-2.39.69c-.7.46-1.24,1.1-1.63,1.9-.39.81-.58,1.7-.58,2.68s.19,1.88.58,2.68c.39.81.93,1.44,1.63,1.9.7.46,1.49.69,2.39.69s1.67-.23,2.38-.7c.71-.47,1.25-1.1,1.65-1.9.4-.8.59-1.69.59-2.67s-.2-1.87-.59-2.67Z" fill="#030e4f"/>
              <path d="m101.8,89.35c.26.24.39.55.39.93s-.13.67-.39.91-.58.36-.96.36h-7.15c-.38,0-.7-.13-.96-.38-.26-.26-.38-.58-.38-.96v-12.72c0-.38.13-.7.4-.96s.6-.39,1.01-.39c.37,0,.68.13.95.39s.4.58.4.96v11.48h5.74c.38,0,.7.12.96.36Z" fill="#030e4f"/>
              <path d="m111.25,91.38c-.82-.26-1.59-.71-2.31-1.34-.4-.35-.59-.74-.59-1.17,0-.34.12-.63.37-.89.25-.26.55-.38.9-.38.28,0,.53.09.75.26.59.48,1.16.84,1.72,1.06.56.22,1.22.33,1.98.33.82,0,1.53-.18,2.12-.55.59-.37.89-.82.89-1.36,0-.66-.29-1.18-.88-1.55-.59-.37-1.52-.66-2.79-.85-3.21-.47-4.82-1.96-4.82-4.49,0-.92.24-1.73.73-2.41.48-.68,1.14-1.2,1.98-1.56s1.77-.54,2.79-.54c.92,0,1.79.14,2.61.42.81.28,1.49.65,2.04,1.1.43.34.64.73.64,1.17,0,.34-.12.63-.37.89-.25.26-.54.38-.88.38-.22,0-.42-.07-.59-.2-.38-.31-.91-.59-1.59-.85s-1.29-.38-1.84-.38c-.92,0-1.64.17-2.15.52-.51.34-.76.8-.76,1.35,0,.63.26,1.11.78,1.43.52.32,1.34.59,2.45.79,1.26.22,2.27.5,3.02.85.76.34,1.32.81,1.71,1.41.38.59.57,1.38.57,2.37,0,.92-.26,1.73-.78,2.43-.52.7-1.21,1.23-2.08,1.61s-1.8.56-2.79.56c-1.06,0-1.99-.13-2.82-.4Z" fill="#030e4f"/>

            <path d="m138.73,45.28c-.88-2.88-1.96-5.69-3.25-8.37l-16.69,3.78c-1.83-3.16-4.05-6.09-6.59-8.73l8.89-14.12c-3.71-3.32-7.83-6.22-12.29-8.62l-11.19,12.51c-3.36-1.5-6.93-2.63-10.66-3.34l-1.42-16.46c-2.49-.29-5.03-.44-7.6-.44s-5.11.15-7.6.44l-1.42,16.46c-3.73.7-7.3,1.83-10.66,3.34l-11.19-12.51c-4.45,2.4-8.58,5.29-12.29,8.62l8.89,14.12c-2.54,2.64-4.76,5.56-6.59,8.73l-16.69-3.78c-1.33,2.75-2.42,5.63-3.32,8.58m37.59-.49c4.97-7.57,13.52-12.56,23.25-12.56s18.28,5,23.25,12.56" fill="#fff" stroke="#c19f58" strokeWidth={3}/>
          </svg>
        </Box>
      </Sheet>

      </React.Fragment>
    );
  }

export default FannieMaeHome;