import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import ColorSchemeToggle from '../components/ColorSchemeToggle';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import * as Icon from 'react-feather';
import axios from 'axios';

const indicators = [
  {value: 'total-housing-starts', label: 'Total Housing Starts'},
  {value: 'single-family-1-unit-housing-starts', label: 'Single Family 1 Unit Housing Starts'},
  {value: 'multifamily-2+units-housing-starts', label: 'Multifamily 2+ Units Housing Starts'},
  {value: 'total-home-sales', label: 'Total Home Sales'},
  {value: 'new-single-family-home-sales', label: 'New Single Family Home Sales'},
  {value: 'existing-single-family-condos-coops-home-sales', label: 'Existing Single Family Condos Coops Home Sales'},
  {value: 'median-new-home-price', label: 'Median New Home Price'},
  {value: 'median-existing-home-price', label: 'Median Existing Home Price'},
  {value: 'federal-housing-finance-agency-purchase-only-house-price-index', label: 'Federal Housing Finance Agency Purchase Only Price Index'},
  {value: '30-year-fixed-rate-mortgage', label: '30 Year Fixed Rate Mortgage'},
  {value: '5-year-adjustable-rate-mortgage', label: '5 Year Adjustable Rate Mortgage'},
  {value: 'single-family-mortgage-originations', label: 'Single Family Mortgage Originations'},
  {value: 'single-family-purchase-mortgage-originations', label: 'Single Family Purchase Mortgage Originations'},
  {value: 'single-family-refinance-mortgage-originations', label: 'Single Family Refinance Mortgage Originations'},
  {value: 'refinance-share-of-total-single-family-mortgage-originations', label: 'Refinance Share of Total Single Family Mortgage Originations'}
]

function getTableHeaders(data) {
  return (
    <tr>
      <th colSpan={5} style={{ textAlign: 'center' }}>{data[0].year}</th>
      <th colSpan={5} style={{ textAlign: 'center' }}>{data[5].year}</th>
      <th colSpan={5} style={{ textAlign: 'center' }}>{data[10].year}</th>
    </tr>
  )
}

function getTableRowData(data) {
  return (
    <tr>
      {data.map((val, rowID) => 
        <td key={rowID} style={{ textAlign: 'center', fontWeight: '700', backgroundColor: val.forecast ? '#d8d8d8' : '', color: val.forecast ? '#1c5fd3' : '' }}>
          {val.value}
        </td>
      )}
    </tr>
  )
}

function FM_HousingIndicators() {
  const [selectedIndicator, setSelectedIndicator] = useState('');
  const [values, setValues] = useState('');
  const [inidcatorLabel, setLabel] = useState('');
  const [unit, setUnit] = useState('');

  const getData = () => {
    if (selectedIndicator != null) {
      const url = `${process.env.REACT_APP_API_URL}/fm/housing-indicators/indicators/${selectedIndicator}`;
      //console.log(url);
      axios.get(`${url}`)
      .then((response) => {
        const data = response.data.indicatorTimeSeries;
        //console.log(data);
        const lastSet = data.slice(-1);
        //console.log(lastSet);
        const chartData = lastSet[0].points.slice(-15);
        //console.log(chartData);
        setUnit(chartData[0].unit);
        setValues(chartData);
      })
      .catch(error => console.error(`Error: $(error)`));
    }
  };

  const selectIndicatorChange = (e, newValue) => {
    //console.log(newValue);
    indicators.find(function(item, index) {
      if(item.value === newValue)
        setLabel(item.label)
    })
    setSelectedIndicator(newValue);
  };

  useEffect(() => {
    // run something every time indicator changes
    if (selectedIndicator !== '') {
      //console.log('Selected Indicator: ' + selectedIndicator);
       getData();
    }
  }, [selectedIndicator])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<Icon.ChevronRight />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          {/* <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            href="/"
            aria-label="Home"
            disabled
          >
            <Icon.Home />
          </Link> */}
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            href="/fm"
          >
              Financial Tools
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            Housing Indicators
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          Housing Indicators
        </Typography>
        
      </Box>
      <Box className="SearchAndFilters-tabletUp">
        <FormControl size="lg">
          <FormLabel>Fannie Mae’s Economic & Strategic Research Group provides analysis of current and historical data and forecasts economic trends in the housing and mortgage-finance markets.</FormLabel>
        </FormControl>
      </Box>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: {
            xs: 'flex',
            sm: 'flex',
          },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '100%',
              md: '200px',
            },
          },
        }}
      >
        <FormControl size="md">
          <FormLabel>Indicator</FormLabel>
          <Select
            placeholder="Choose indicator"
            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } }, listbox: {placement: 'bottom-start'} }}
            onChange={selectIndicatorChange}
          >
            {indicators.map(option => (
                <Option value={option.value} key={option.value}>{option.label}</Option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >

       

        {selectedIndicator != null && values ?
         (
          <>
            <>
              <Box sx={{ px: 2, py: 2, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography level="h3" textColor="text.primary">
                  {inidcatorLabel}
                </Typography>
                <Typography level="body1" textColor="text.primary">
                  Unit: {unit}
                </Typography>
              </Box>
            </>
            
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': (theme) => theme.vars.palette.background.level1,
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': (theme) => theme.vars.palette.background.level1,
              }}
              borderAxis={'bothBetween'}
            >
                <thead>
                  {getTableHeaders(values)}
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q1</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q2</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q3</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q4</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>EOY</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q1</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q2</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q3</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q4</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>EOY</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q1</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q2</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q3</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>Q4</td>
                    <td style={{ width: 140, padding: 12, textAlign: 'center' }}>EOY</td>
                  </tr>
                  {getTableRowData(values)}

                </tbody>
              </Table>

              </>
         ) : (
          <Box
          sx={{
            p: 2,
          }}>
             <Typography fontWeight="md">Choose an indicator</Typography>
          </Box>
         )}
      </Sheet>

      <Box sx={{ py: 2, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography level="body1" textColor="text.primary">
            NOTES:
          </Typography>
          <Typography level="body1" textColor="text.primary">
            SAAR - Seasonally Adjusted Annual Rate<br></br>
            NSA - Net Sellable Area<br></br>
          </Typography>
          <Typography
            variant="soft"
            textColor="#1c5fd3"
            level="body1"
            fontWeight="600"
            backgroundColor="#d8d8d8"
          >
            Highlighted values are forecasted
        </Typography>
        </Box>
      
    </React.Fragment>
    );
  }

export default FM_HousingIndicators;